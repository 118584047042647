// Amplify
:root {
  --amplify-colors-teal-10: #3f51bf !important;
  --amplify-colors-teal-20: #3f51bf !important;
  --amplify-colors-teal-40: #3f51bf !important;
  --amplify-colors-teal-60: #3f51bf !important;
  --amplify-colors-teal-80: #3f51bf !important;
  --amplify-colors-teal-100: #3f51bf !important;
}


// Here you can add other styles

:root {
  --amplify-colors-black: hsl(0, 0%, 0%);
  --amplify-colors-blue-10: hsl(220, 95%, 95%);
  --amplify-colors-blue-100: hsl(220, 100%, 15%);
  --amplify-colors-blue-20: hsl(220, 85%, 85%);
  --amplify-colors-blue-40: hsl(220, 70%, 70%);
  --amplify-colors-blue-60: hsl(220, 50%, 50%);
  --amplify-colors-blue-80: hsl(220, 95%, 30%);
  --amplify-colors-blue-90: hsl(220, 100%, 20%);
  --amplify-colors-border-disabled: var(--amplify-colors-border-tertiary);
  --amplify-colors-border-error: var(--amplify-colors-red-80);
  --amplify-colors-border-focus: var(--amplify-colors-primary-100);
  --amplify-colors-border-info: var(--amplify-colors-blue-80);
  --amplify-colors-border-pressed: var(--amplify-colors-primary-100);
  --amplify-colors-border-primary: var(--amplify-colors-neutral-60);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-success: var(--amplify-colors-green-80);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-warning: var(--amplify-colors-orange-80);
  --amplify-colors-font-active: var(--amplify-colors-primary-100);
  --amplify-colors-font-disabled: var(--amplify-colors-neutral-60);
  --amplify-colors-font-error: var(--amplify-colors-red-90);
  --amplify-colors-font-focus: var(--amplify-colors-primary-100);
  --amplify-colors-font-hover: var(--amplify-colors-primary-90);
  --amplify-colors-font-info: var(--amplify-colors-blue-90);
  --amplify-colors-font-interactive: var(--amplify-colors-primary-80);
  --amplify-colors-font-inverse: var(--amplify-colors-white);
  --amplify-colors-font-primary: var(--amplify-colors-neutral-100);
  --amplify-colors-font-secondary: var(--amplify-colors-neutral-90);
  --amplify-colors-font-success: var(--amplify-colors-green-90);
  --amplify-colors-font-tertiary: var(--amplify-colors-neutral-80);
  --amplify-colors-font-warning: var(--amplify-colors-orange-90);
  --amplify-colors-green-10: hsl(130, 60%, 95%);
  --amplify-colors-green-100: hsl(130, 22%, 23%);
  --amplify-colors-green-20: hsl(130, 60%, 90%);
  --amplify-colors-green-40: hsl(130, 44%, 63%);
  --amplify-colors-green-60: hsl(130, 43%, 46%);
  --amplify-colors-green-80: hsl(130, 33%, 37%);
  --amplify-colors-green-90: hsl(130, 27%, 29%);
  --amplify-colors-neutral-10: hsl(0, 0%, 95%);
  --amplify-colors-neutral-100: hsl(0, 0%, 15%);
  --amplify-colors-neutral-20: hsl(0, 0%, 90%);
  --amplify-colors-neutral-40: hsl(0, 0%, 70%);
  --amplify-colors-neutral-60: hsl(0, 0%, 50%);
  --amplify-colors-neutral-80: hsl(0, 0%, 30%);
  --amplify-colors-neutral-90: hsl(0, 0%, 20%);
  --amplify-colors-orange-10: hsl(30, 100%, 95%);
  --amplify-colors-orange-100: hsl(30, 100%, 15%);
  --amplify-colors-orange-20: hsl(30, 100%, 90%);
  --amplify-colors-orange-40: hsl(30, 100%, 70%);
  --amplify-colors-orange-60: hsl(30, 100%, 50%);
  --amplify-colors-orange-80: hsl(30, 100%, 30%);
  --amplify-colors-orange-90: hsl(30, 100%, 20%);
  --amplify-colors-primary-10: #663399;
  --amplify-colors-primary-100: #663399;
  --amplify-colors-primary-20: #663399;
  --amplify-colors-primary-40: #663399;
  --amplify-colors-primary-60: #663399;
  --amplify-colors-primary-80: #663399;
  --amplify-colors-primary-90: #663399;
  --amplify-colors-red-10: hsl(0, 100%, 95%);
  --amplify-colors-red-100: hsl(0, 100%, 15%);
  --amplify-colors-red-20: hsl(0, 100%, 90%);
  --amplify-colors-red-40: hsl(0, 100%, 70%);
  --amplify-colors-red-60: hsl(0, 100%, 50%);
  --amplify-colors-red-80: hsl(0, 100%, 30%);
  --amplify-colors-red-90: hsl(0, 100%, 20%);
  --amplify-colors-white: hsl(0, 0%, 100%);
}

.header {
  min-height: 4rem;
  border: 0;

  .input-group .form-control {
    --cui-body-color: var(--cui-body-color-dark);
    --cui-input-placeholder-color: var(--cui-secondary-color-dark);
  }
}

.sidebar-brand-full {
  margin-left: 3px;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.sidebar-toggler {
  @include ltr-rtl("margin-left", auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  --cui-sidebar-padding-x: #{$spacer};

  .sidebar-toggler {
    display: none;
  }

  .sidebar-nav {
    --cui-sidebar-nav-padding-x: #{$spacer * .5};
  }
}

.sidebar-nav {
  @include transition(padding .15s);
}

.card,
.dropdown-menu,
.footer,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .sidebar {
      background-color: var(--cui-body-bg) !important; // stylelint-disable-line
      background-image: none !important; // stylelint-disable-line
      border: 0;
    }
  }
}

// Domeseer Styles

.header {
  --cui-header-bg: #ffffff;
  --cui-header-color: #333333;
  --cui-header-hover-color: #333333;
  --cui-header-toggler-color: #333333;
  --cui-body-color-dark: #252b36;
  --cui-input-placeholder-color: #252b36;
}
.form-label {
  font-size: 0.88rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.toast-body {
  color: white;
}

td.text-truncate {
  max-width: 5rem;
}

.simpledropdown .actionDropdown .dropdown-toggle,
.simpledropdown .actionDropdown .dropdown-toggle:hover,
.simpledropdown .actionDropdown .dropdown-toggle:active {
  background: none;
  border: none;
}

.simpledropdown .actionDropdown .dropdown-toggle:after {
  display: none;
}

.accordion-header {
  font-weight: 600;
}

.modal-backdrop:has(+ .secondaryModal) {
  z-index: 1060 !important;
}
.secondaryModal {
  z-index: 1065 !important;
}

.modal-backdrop:has(+ .teritiaryModal) {
  z-index: 1070 !important;
}
.teritiaryModal {
  z-index: 1075 !important;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.shakeanime {
    animation: shake 2s ease-in-out 0s 1;
    //box-shadow: 0 0 0.5em rgb(1, 198, 1);
  }

@keyframes shake {
  0% { margin-left: 0rem; box-shadow: 0px 0px 6px 6px #05ae0d55;  }
  5% { margin-left: 0.5rem; }
  10% { margin-left: -0.5rem; }
  15% { margin-left: 0rem; }
  100% { box-shadow: 0px 0px 0px 0px #ffffff; }
}
.errorbg {
  background-color: rgba(253, 196, 196, 0.977) !important;
}

/* Custom Scrollbar Styles */
.custom-scrollbar {
  overflow-y: scroll;

}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #bababa;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}